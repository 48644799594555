import logo from './logo.svg';
import './App.css';
import styled from 'styled-components';
import Switch from "react-switch";
import {useState, useEffect} from "react";
import { createGlobalStyle } from 'styled-components'

let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const GlobalStyle = createGlobalStyle`
  body {
    background: ${props => props.lightMode ? 'white' : '#121212'};
  }
`

let AppDiv = styled.div`
  width: 100vw;
  height: calc(100vh + 400px);
  background: ${props => props.lightMode ? 'white' : '#121212'};
  color: ${props => props.lightMode ? '#121212' : 'rgba(255, 255, 255, 0.8)'};
  @media screen and (max-width: 800px) {
    height: auto;
    padding-bottom:30px;
  }
`
let NameHeader = styled.div`
  font-family: HKNova;
  font-size: 38px;
  text-align: left;
  margin-left: 50px;
  padding-top: 35px;
  letter-spacing: 2px;
  
  @media screen and (max-width: 800px) {
    text-align: center;
    margin-left: 0px;
    font-size: 35px;
  }
`

const BlurbText = styled.div`

`

let Blurb = styled.div`
  font-family: Avenir, sans-serif;
  font-size: 20px;
  text-align: left;
  margin-top: 35px;
  width: calc(100% - 110px);;
  line-height: 34px;
  display:inline-block;
  
  @media screen and (max-width: 800px) {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 40px);
    padding: 20px;
  }
`

let BlurbLink = styled.a`
    text-decoration: none;
    font-weight: 500;
    color: #c5167f;
`

let BlurbLinkMusic = styled.a`
    text-decoration: none;
    font-weight: 500;
    color: #6f39e3;
`

let BlurbLinkJournalism = styled.a`
    text-decoration: none;
    font-weight: 500;
    color: #57a1fd;
`

let ProfileImage = styled.img`
    margin-left: 50px;
    margin-top: 10px;
    margin-bottom: 100px;
    display: ${isSafari ? 'block' : 'none'};
    
    @media screen and (max-width: 800px) {
      display: ${isSafari ? 'block' : 'none'};
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
    }
  }
`

let SwitchPanel = styled.div`
    display: inline-flex;
    float: left;
    width: 110px;
    flex-direction: column;
    height: calc(100vh + 100px);
    align-items: center;
    justify-content:flex-start;
    padding-top: 40px;
    @media screen and (max-width: 800px) {
      display: none;
    }
`

let MobileSwitchPanel = styled.div`
    display: none;
    width: 100vw;
    align-items: start;
    margin-top: 10px;
    margin-bottom: -20px;
    justify-content:center;
    padding: 10px;
    @media screen and (max-width: 800px) {
      display: flex;
    }
`
let darkFilter = 'invert(13%) sepia(1%) saturate(0%) hue-rotate(331deg) brightness(92%) contrast(83%)'
let lightFiler = 'invert(99%) sepia(98%) saturate(94%) hue-rotate(181deg) brightness(119%) contrast(82%)'

let AppIcon = styled.img`
  margin-top: 28px;
  filter: ${props => props.lightMode ? darkFilter : lightFiler };
  &:hover {
    opacity: 1.0;
    filter: invert(47%) sepia(93%) saturate(2216%) hue-rotate(219deg) brightness(94%) contrast(95%);
  }
`



function App() {

  const [checked, setChecked] = useState(false);
  const handleChange = nextChecked => {
    setChecked(nextChecked);
  };

	useEffect(() => {
		const script = document.createElement('script');
		script.src = "./emj-particle.min.js";
		script.async = true;
		document.body.appendChild(script);
		return () => {
			document.body.removeChild(script);
		}
	}, []);

  return (
    <AppDiv lightMode={checked} className="App">
      <GlobalStyle lightMode={checked} />
	    <script type="text/javascript" src="emj-particle.min.js"/>

      <SwitchPanel>
        <Switch
          onChange={handleChange}
          checked={checked}
          onColor={'#7671E9'}
          checkedIcon={false}
          uncheckedIcon={false}
        />

        <a rel="noreferrer" target="_blank" href={"https://twitter.com/EugeneMJoseph"}>
          <AppIcon style={{marginTop:34}} lightMode={checked} width={38} height={38} src="twitter.png"/>
        </a>

        <a rel="noreferrer" target="_blank" href={"https://www.linkedin.com/in/eugenemjoseph/"}>
          <AppIcon lightMode={checked} width={34} height={34} src="linkedin.png"/>
        </a>

        <a rel="noreferrer" target="_blank" href={"https://stackoverflow.com/users/2529253/eugene"}>
          <AppIcon lightMode={checked} width={35} height={35} src="stack.png"/>
        </a>

        <a rel="noreferrer" target="_blank" href={"https://medium.com/@eugenemjoseph"}>
          <AppIcon lightMode={checked} width={36} height={36} src="medium.png"/>
        </a>
      </SwitchPanel>

      <NameHeader>Eugene M. Joseph</NameHeader>
      <MobileSwitchPanel>
        <Switch
            onChange={handleChange}
            checked={checked}
            onColor={'#7671E9'}
            checkedIcon={false}
            uncheckedIcon={false}
        />
        <a rel="noreferrer" target="_blank" href={"https://twitter.com/EugeneMJoseph"}>
          <AppIcon style={{marginTop:-6, marginLeft:30}} lightMode={checked} width={38} height={38} src="twitter.png"/>
        </a>

        <a rel="noreferrer" target="_blank" href={"https://www.linkedin.com/in/eugenemjoseph/"}>
          <AppIcon style={{marginTop:-5, marginLeft:30}} lightMode={checked} width={34} height={34} src="linkedin.png"/>
        </a>

        <a rel="noreferrer" target="_blank" href={"https://stackoverflow.com/users/2529253/eugene"}>
          <AppIcon style={{marginTop:-5, marginLeft:30}} lightMode={checked} width={35} height={35} src="stack.png"/>
        </a>

        <a rel="noreferrer" target="_blank" href={"https://medium.com/@eugenemjoseph"}>
          <AppIcon style={{marginTop:-5, marginLeft:30}} lightMode={checked} width={36} height={36} src="medium.png"/>
        </a>

      </MobileSwitchPanel>
      <Blurb>
        <BlurbText>
          <b>Hey, I'm Eugene!</b> I'm a software and machine learning engineer, musician, and computational journalist.
          <br/><br/>
          Currently building <BlurbLink target="_blank" href={'https://talkwell.ai'}>Talkwell</BlurbLink>. An AI-powered communication coach based decades of research from the leading marriage researchers to help couples and families better understand and improve their communication.
          <br/>Also consulting for select companies via <BlurbLink target="_blank" href={'https://overtonelabs.com'}>Overtone Labs</BlurbLink>.
          <br/><br/>
          My first EP <b>Shades</b> is available on <BlurbLinkMusic target="_blank" href={'https://eugenemjoseph.bandcamp.com/'}>BandCamp</BlurbLinkMusic>. My second EP <b>Lost & Found</b> is available on <BlurbLinkMusic target="_blank" href={"https://open.spotify.com/album/6gQFKw4uy3l5pOock6RhNh"}>Spotify</BlurbLinkMusic> and <BlurbLinkMusic target="_blank" href={"https://music.apple.com/us/album/lost-and-found-ep/1348228075"}>Apple Music</BlurbLinkMusic>. Recording some new tracks for a third record at the moment.
          {/*<br/><br/>*/}
          {/*In the process of setting up a computational journalism publication called <BlurbLinkJournalism target="_blank" href={'#'}>The Nth Estate</BlurbLinkJournalism>.*/}
        </BlurbText>

        { (!isSafari || true) &&
        <ProfileImage
            data-init-position="none"
            data-init-direction="none"
            data-fade-position="none"
            data-fade-direction="none"
            data-noise="1.5"
            data-gravity="0.125"
            data-renderer="webgl"
            data-width="380"
            data-height="380"
            data-min-width="360"
            data-min-height="360"
            data-particle-size="3"
            data-particle-gap="3"
            data-mouse-force="60"
            className="next-particle"
            width={0}
            height={0}
            src={process.env.PUBLIC_URL + '/emj.png'}/>
        }

        {/*{ (isSafari) &&*/}
        {/*<ProfileImage*/}
        {/*    width={320}*/}
        {/*    height={320}*/}
        {/*    src={process.env.PUBLIC_URL + '/emj.png'}*/}
        {/*/>*/}
        {/*}*/}

      </Blurb>


    </AppDiv>
  );
}

export default App;
